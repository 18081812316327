
import React, { useState } from 'react';
//import { initialGameState } from './initialStates';
import logo from './logo.svg';
import './App.css';
import { CSSTransition } from "react-transition-group";
import {Howl} from 'howler';

function App() {
  const getImg = ()=>{
    return "imgs/p.png";
  }

  const [clicks, setClicks] = useState(0);
  const [clickState, setClickState] = useState(false);
  const [catImg, setCatImg] = useState(getImg);
  const [folderName, setFolderName] = useState(process.env.PUBLIC_URL);
  const nodeRef = React.useRef(null);

  const getAnimClass = ()=>{
    return ""//.pop-out-enter-active";
  }

  const playSnd = ()=>{
    const sound = new Howl({
      src: [folderName+"/pop3.ogg"],
    });
    sound.loop(false);
    sound.volume(1);
    sound.play();

  }
  
  const clickStart = (e)=>{
    setFolderName(process.env.PUBLIC_URL);
    setCatImg("imgs/op.png");
    setClicks(clicks+1);
    setClickState(true);
    //console.log("process.env.PUBLIC_URL", process.env.PUBLIC_URL);
    //console.log("folderName", folderName);
    playSnd();
  };

  const clickEnd = (e)=>{
    setClickState(false);
  };

  return (
    <div id="app" onPointerDown={clickStart} onPointerUp={clickEnd} onLostPointerCapture={clickEnd}> 
     
        <img src={logo} className="title" alt="POPCAT" />

        <CSSTransition
          nodeRef={nodeRef}
          in={clickState}
          timeout={30}
          classNames="catFace"
          onEnter={()=>{setCatImg("imgs/op.png");}}
          onExited={()=>{setCatImg("imgs/p.png");}}
        >
            <div ref={nodeRef} className={`counter p ${getAnimClass()}`} style={{ 
              draggable:false,
              backgroundImage: `url(${folderName+"/"+catImg})` 
            }}>
                <div className="count">{clicks}</div>
            </div>
        </CSSTransition>
        

    <div>

    </div>
    </div>
  );
}

export default App;
